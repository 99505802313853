import Vue from "vue";
import Vuex from "vuex";

import createPersistedState from "vuex-persistedstate";

import {
  //firestore_fieldvalue,
  fire_auth,
  fire_auth_provider,
  fire_functions,
  fire_store,
  fire_storage,
} from "@/firebase/init";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],

  state: {
    auth: null,
    userData: {},
    metaDoc: {
      courses: [],
    },
    pdfDoc: {
      pdfGroups: [],
    },
    highlightedTests: [],
    results: {},
    courseTests: {}, //courseName: testsArr
    premiumPass: {
      discountPercentage: 0,
      passes: [],
    },
    unsubscribeUserData: null,
    testGroupCounter: {},
    activeTestData: {}
  },

  getters: {
    auth: (state) => state.auth,
    userData: (state) => state.userData,
    courses: (state) => state.metaDoc.courses,
    pdfGroups: (state) => state.pdfDoc.pdfGroups,
    highlightedTests: (state) => state.highlightedTests,
    results: (state) => state.results,
    courseTests: (state) => state.courseTests,
    subPasses: (state) => state.premiumPass.passes,
    unsubscribeUserData: (state) => state.unsubscribeUserData,
    testGroupCounter: (state) => state.testGroupCounter,
    discountPercentage: (state) => state.premiumPass.discountPercentage,
    activeTestData: (state) => state.activeTestData,
  },

  mutations: {
    //courses and vistors etc.
    setMetaDoc: (state, payload) => {
      state.metaDoc = payload;
    },

    setPdfDoc: (state, payload) => {
      state.pdfDoc = payload;
    },

    // Auth Mutations
    setAuth: (state, payload) => {
      state.auth = payload;
    },

    setUserData: (state, payload) => {
      state.userData = payload;
    },

    logout: (state) => {
      state.auth = null;
      state.userData = {};
      state.metaDoc = {
        courses: [],
      };
      state.highlightedTests = [];
      state.results = {};
      state.selectedCourseTests = [];
      state.premium_pass = {
        passes: [],
      };
      state.testsMetaData = {};
      state.unsubscribeUserData = null;
      state.activeTestData = {};
      sessionStorage.clear();
    },

    // Dashboard Mutations
    setHighlightedTests: (state, payload) => {
      state.highlightedTests = payload;
    },

    addResults: (state, payload) => {
      Object.keys(payload).forEach((testID) => {
        Vue.set(state.results, testID, payload[testID]);
      });
    },

    setCourseTests: (state, payload) => {
      Vue.set(state.courseTests, payload.courseName, payload.testResArr);
    },

    setTestGroupCounter: (state, payload) => {
      state.testGroupCounter = payload;
    },

    //Profile Mutations
    setProfilePhotoURL: (state, payload) => {
      Vue.set(state.userData, "photoURL", payload);
    },

    setProfile: (state, payload) => {
      Vue.set(state.userData, "fullName", payload.fullName);
      Vue.set(state.userData, "phone", payload.phone);
      Vue.set(state.userData, "selectedCourse", payload.selectedCourse);
    },

    setUserEmail: (state, payload) => {
      Vue.set(state.userData, "email", payload);
    },

    //Subscribe Mutations
    setPremiumPass: (state, payload) => {
      state.premiumPass = payload;
    },

    setUnsubscribeUserData: (state, payload) => {
      state.unsubscribeUserData = payload;
    },

    //Test Mutation
    addResult: (state, payload) => {
      Vue.set(state.results, payload.testID, payload);
    },

    setToActiveTestData: (state, payload) => {
      Vue.set(state.activeTestData, payload.key, payload.data);
    },

    resetActiveTestData: (state) => {
      state.activeTestData = {
        instructionsLang: "en"
      };
    },
  },

  actions: {
    // Public Actions
    // Get all courses and their logo links
    getMetaDoc: (context) => {
      const ref = fire_store.collection("meta").doc("meta_doc");

      return new Promise((resolve, reject) => {
        ref
          .get()
          .then((res) => {
            const data = res.data();
            context.commit("setMetaDoc", data);
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getPDFDoc: (context) => {
      const ref = fire_store.collection("meta").doc("pdf_doc");

      return new Promise((resolve, reject) => {
        ref
          .get()
          .then((res) => {
            const data = res.data();
            context.commit("setPdfDoc", data);
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getpdfs: (context, payload) => {
      var ref = "";

      switch (payload.requestType) {
        case "prev":
          ref = fire_store
            .collection("pdfs")
            .where("selectedFolder", "==", payload.folder)
            .where("selectedGroup", "==", payload.group)
            .orderBy("id", "desc")
            .endBefore(payload.doc)
            .limit(10);
          break;
        case "next":
          ref = fire_store
            .collection("pdfs")
            .where("selectedFolder", "==", payload.folder)
            .where("selectedGroup", "==", payload.group)
            .orderBy("id", "desc")
            .startAfter(payload.doc)
            .limit(10);

          break;
        default:
          ref = fire_store
            .collection("pdfs")
            .where("selectedFolder", "==", payload.folder)
            .where("selectedGroup", "==", payload.group)
            .orderBy("id", "desc")
            .limit(10);
      }

      return new Promise((resolve, reject) => {
        ref
          .get()
          .then((snapshot) => {
            const resData = snapshot.docs.map((doc) => doc.data());
            const res = {
              data: resData,
              firstAndLastVisible: {
                firstVisible: snapshot.docs[0],
                lastVisible: snapshot.docs[snapshot.docs.length - 1],
              },
            };
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    //submit landing page contact form
    submitContactForm: (context, payload) => {
      const ref = fire_store.collection("contact_form").doc();
      return new Promise((resolve, reject) => {
        ref
          .set(payload)
          .then(() => {
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    getAllCourseTests: (context, payload) => {
      var ref = "";

      switch (payload.requestType) {
        case "prev":
          ref = fire_store
            .collection("testsv2")
            .where("selectedCourses", "array-contains", payload.course)
            .orderBy("id", "desc")
            .endBefore(payload.doc)
            .limit(10);
          break;
        case "next":
          ref = fire_store
            .collection("testsv2")
            .where("selectedCourses", "array-contains", payload.course)
            .orderBy("id", "desc")
            .startAfter(payload.doc)
            .limit(10);
          break;
        default:
          ref = fire_store
            .collection("testsv2")
            .where("selectedCourses", "array-contains", payload.course)
            .orderBy("id", "desc")
            .limit(10);
      }

      return new Promise((resolve, reject) => {
        ref
          .get()
          .then((snapshot) => {
            const testResArr = snapshot.docs.map((doc) => doc.data());

            var res = {
              tests: testResArr,
              results: {},
              firstAndLastVisible: {
                firstVisible: snapshot.docs[0],
                lastVisible: snapshot.docs[snapshot.docs.length - 1],
              },
            };

            // if user is auth grab results too
            if (!context.state.auth) {
              resolve(res);
              return;
            }

            var resultPromises = [];

            testResArr.forEach((testObj) =>
              resultPromises.push(
                fire_store
                  .collection("resultsv2")
                  .doc(testObj.id)
                  .collection("users")
                  .doc(context.state.auth.uid)
                  .get()
              )
            );

            Promise.all(resultPromises)
              .then((resultResArr) => {
                // resultResArr is array of objects for each result respective to tests

                resultResArr.forEach((rawResultDoc) => {
                  if (rawResultDoc.exists) {
                    var docData = rawResultDoc.data();
                    res.results[docData.testID] = docData;
                  }
                });

                context.commit("addResults", res.results);

                resolve(res);
              })
              .catch((error) => reject(error));
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getAllTestGroupTests: (context, payload) => {
      var ref = "";

      switch (payload.requestType) {
        case "prev":
          ref = fire_store
            .collection("testsv2")
            .where(
              "selectedCourses",
              "array-contains",
              `${payload.course}_${payload.testGroup}`
            )
            .orderBy("id", "desc")
            .endBefore(payload.doc)
            .limit(10);
          break;
        case "next":
          ref = fire_store
            .collection("testsv2")
            .where(
              "selectedCourses",
              "array-contains",
              `${payload.course}_${payload.testGroup}`
            )
            .orderBy("id", "desc")
            .startAfter(payload.doc)
            .limit(10);
          break;
        default:
          ref = fire_store
            .collection("testsv2")
            .where(
              "selectedCourses",
              "array-contains",
              `${payload.course}_${payload.testGroup}`
            )
            .orderBy("id", "desc")
            .limit(10);
      }

      return new Promise((resolve, reject) => {
        ref
          .get()
          .then((snapshot) => {
            const testResArr = snapshot.docs.map((doc) => doc.data());

            var res = {
              tests: testResArr,
              results: {},
              firstAndLastVisible: {
                firstVisible: snapshot.docs[0],
                lastVisible: snapshot.docs[snapshot.docs.length - 1],
              },
            };

            // if user is auth grab results too
            if (!context.state.auth) {
              resolve(res);
              return;
            }

            var resultPromises = [];

            testResArr.forEach((testObj) =>
              resultPromises.push(
                fire_store
                  .collection("resultsv2")
                  .doc(testObj.id)
                  .collection("users")
                  .doc(context.state.auth.uid)
                  .get()
              )
            );

            Promise.all(resultPromises)
              .then((resultResArr) => {
                // resultResArr is array of objects for each result respective to tests

                resultResArr.forEach((rawResultDoc) => {
                  if (rawResultDoc.exists) {
                    var docData = rawResultDoc.data();
                    res.results[docData.testID] = docData;
                  }
                });

                context.commit("addResults", res.results);

                resolve(res);
              })
              .catch((error) => reject(error));
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // Auth Actions
    login: (context, payload) => {
      return new Promise((resolve, reject) => {
        fire_auth
          .signInWithEmailAndPassword(payload.email, payload.password)
          .then((cred) => {
            //if login successful
            if (cred.user) {
              //get user data and login locally
              context
                .dispatch("getUserData")
                .then(() => {
                  context.commit("setAuth", cred.user);
                  resolve();
                })
                .catch((error) => {
                  reject(error);
                });
            } else {
              reject("Invalid Email or Password. Error Code: ERROR_21");
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    signInWithGoogle: (context) => {
      var provider = new fire_auth_provider.GoogleAuthProvider();
      return new Promise((resolve, reject) => {
        fire_auth
          .signInWithPopup(provider)
          .then((result) => {
            //successful google login
            if (result.user) {
              //check if registration or login
              const userDataRef = fire_store
                .collection("users")
                .doc(result.user.uid);

              //get userData doc
              userDataRef.get().then((doc) => {
                if (doc.exists) {
                  //is login
                  context.commit("setUserData", doc.data());
                  // to start a listener on user data doc
                  context.dispatch("getUserData");
                  context.commit("setAuth", result.user);
                  resolve();
                } else {
                  //is register
                  // create a new document for the user
                  const userData = {
                    email: result.user.email,
                    fullName: result.user.displayName,
                    phone: result.user.phoneNumber,
                    uid: result.user.uid,
                    photoURL: result.user.photoURL,
                  };

                  fire_store
                    .collection("users")
                    .doc(result.user.uid)
                    .set(userData)
                    .then(() => {
                      context.commit("setUserData", userData);
                      // to start a listener on user data doc
                      context.dispatch("getUserData");
                      context.commit("setAuth", result.user);
                      resolve();
                    })
                    .catch((error) => {
                      reject(error.code);
                    });
                }
              });
            } else {
              reject("Invalid Google login. Error Code: ERROR_22");
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    register: (context, payload) => {
      return new Promise((resolve, reject) => {
        fire_auth
          .createUserWithEmailAndPassword(payload.email, payload.password)
          .then((cred) => {
            //if login successful
            if (cred.user) {
              //save user data and login locally
              // create a new document for the user
              const userData = {
                email: payload.email,
                fullName: payload.fullName,
                phone: null,
                uid: cred.user.uid,
              };

              fire_store
                .collection("users")
                .doc(cred.user.uid)
                .set(userData)
                .then(() => {
                  context.commit("setUserData", userData);
                  //to start a listener on user data doc
                  context.dispatch("getUserData");
                  context.commit("setAuth", cred.user);
                  resolve();
                })
                .catch((error) => {
                  reject(error);
                });
            } else {
              reject("Error in registering. Error Code: ERROR_23");
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // starts a listener on userData
    getUserData: (context) => {
      return new Promise((resolve, reject) => {
        if (!fire_auth.currentUser) {
          reject("Invalid Login.  Error Code: ERROR_23");
        } else {
          const uid = fire_auth.currentUser.uid;
          const ref = fire_store.collection("users").doc(uid);
          const unsubscribe = ref.onSnapshot(
            (doc) => {
              if (doc) {
                const data = doc.data();
                context.commit("setUserData", data);
                resolve(data);
              } else {
                reject("Error in fetching user data. Error Code: ERROR_23_1");
              }
            },
            (error) => {
              console.log("snapshot error: ", error);
            }
          );

          context.commit("setUnsubscribeUserData", unsubscribe);
        }
      });
    },

    logout: (context) => {
      return new Promise((resolve, reject) => {
        try {
          context.state.unsubscribeUserData();
        } catch (e) {
          console.log("Logout Error: ", e);
        }

        fire_auth
          .signOut()
          .then(() => {
            context.commit("logout");
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    sendResetPasswordEmail: (context, payload) => {
      return new Promise((resolve, reject) => {
        fire_auth
          .sendPasswordResetEmail(payload)
          .then(() => {
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    //Dashboard Actions
    getHighlightedTests: (context) => {
      const ref = fire_store
        .collection("testsv2")
        .where("isHighlighted", "==", true)
        .orderBy("id", "desc")
        .limit(5);

      return new Promise((resolve, reject) => {
        ref
          .get()
          .then((snapshot) => {
            const testResArr = snapshot.docs.map((doc) => doc.data());
            var resultsResObj = {};

            context.commit("setHighlightedTests", testResArr);

            var resultPromises = [];

            testResArr.forEach((testObj) =>
              resultPromises.push(
                fire_store
                  .collection("resultsv2")
                  .doc(testObj.id)
                  .collection("users")
                  .doc(context.state.auth.uid)
                  .get()
              )
            );

            Promise.all(resultPromises)
              .then((resultResArr) => {
                // resultResArr is array of objects for each result respective to tests

                resultResArr.forEach((rawResultDoc) => {
                  if (rawResultDoc.exists) {
                    var docData = rawResultDoc.data();
                    resultsResObj[docData.testID] = docData;
                  }
                });

                context.commit("addResults", resultsResObj);

                resolve({ testResArr, resultsResObj });
              })
              .catch((error) => reject(error));
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getAllHighlightedTests: (context, payload) => {
      var ref = "";
      switch (payload.requestType) {
        case "prev":
          ref = fire_store
            .collection("testsv2")
            .where("isHighlighted", "==", true)
            .orderBy("id", "desc")
            .endBefore(payload.doc)
            .limit(10);
          break;
        case "next":
          ref = fire_store
            .collection("testsv2")
            .where("isHighlighted", "==", true)
            .orderBy("id", "desc")
            .startAfter(payload.doc)
            .limit(10);
          break;
        default:
          ref = fire_store
            .collection("testsv2")
            .where("isHighlighted", "==", true)
            .orderBy("id", "desc")
            .limit(10);
      }

      return new Promise((resolve, reject) => {
        ref
          .get()
          .then((snapshot) => {
            const testResArr = snapshot.docs.map((doc) => doc.data());

            var res = {
              tests: testResArr,
              results: {},
              firstAndLastVisible: {
                firstVisible: snapshot.docs[0],
                lastVisible: snapshot.docs[snapshot.docs.length - 1],
              },
            };

            // if user is auth grab results too
            if (!context.state.auth) {
              resolve(res);
              return;
            }

            var resultPromises = [];

            testResArr.forEach((testObj) =>
              resultPromises.push(
                fire_store
                  .collection("resultsv2")
                  .doc(testObj.id)
                  .collection("users")
                  .doc(context.state.auth.uid)
                  .get()
              )
            );

            Promise.all(resultPromises)
              .then((resultResArr) => {
                // resultResArr is array of objects for each result respective to tests

                resultResArr.forEach((rawResultDoc) => {
                  if (rawResultDoc.exists) {
                    var docData = rawResultDoc.data();
                    res.results[docData.testID] = docData;
                  }
                });

                context.commit("addResults", res.results);

                resolve(res);
              })
              .catch((error) => reject(error));
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getCourseTests: (context, payload) => {
      const ref = fire_store
        .collection("testsv2")
        .where("selectedCourses", "array-contains", payload)
        .orderBy("id", "asc")
        .limit(5);

      return new Promise((resolve, reject) => {
        ref
          .get()
          .then((snapshot) => {
            const testResArr = snapshot.docs.map((doc) => doc.data());

            var resultsResObj = {};

            context.commit("setCourseTests", {
              courseName: payload,
              testResArr,
            });

            var resultPromises = [];

            if (context.state.auth != null) {
              testResArr.forEach((testObj) => {
                resultPromises.push(
                  fire_store
                    .collection("resultsv2")
                    .doc(testObj.id)
                    .collection("users")
                    .doc(context.state.auth.uid)
                    .get()
                );
              });
            }

            Promise.all(resultPromises)
              .then((resultResArr) => {
                // resultResArr is array of objects for each result respective to tests

                resultResArr.forEach((rawResultDoc) => {
                  if (rawResultDoc.exists) {
                    var docData = rawResultDoc.data();
                    resultsResObj[docData.testID] = docData;
                  }
                });

                context.commit("addResults", resultsResObj);

                resolve({ testResArr, resultsResObj });
              })
              .catch((error) => reject(error));
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getTestGroupCounter: (context) => {
      const testGroupCounterRef = fire_store
        .collection("meta")
        .doc("testGroupCounter");

      return new Promise((resolve, reject) => {
        testGroupCounterRef
          .get()
          .then((res) => {
            const data = res.data();
            context.commit("setTestGroupCounter", data);
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    //Profile Actions
    setProfilePhoto: (context, payload) => {
      const docRef = fire_store.collection("users").doc(context.state.auth.uid);

      //students/[uid]/dp.jpeg
      const storageRef = fire_storage
        .ref()
        .child(`users/${context.state.auth.uid}/dp.jpeg`);

      return new Promise((resolve, reject) => {
        storageRef
          .putString(payload.image_data, "data_url")
          .then((snapshot) => {
            snapshot.ref.getDownloadURL().then((url) => {
              docRef
                .update({ photoURL: url })
                .then(() => {
                  context.commit("setProfilePhotoURL", url);
                  resolve();
                })
                .catch((err) => {
                  reject(err);
                });
            });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    submitProfile: (context, payload) => {
      const ref = fire_store.collection("users").doc(context.state.auth.uid);

      return new Promise((resolve, reject) => {
        ref
          .update(payload)
          .then(() => {
            context.commit("setProfile", payload);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    changeEmail: (context, payload) => {
      var user = fire_auth.currentUser;

      const ref = fire_store.collection("users").doc(context.state.auth.uid);

      return new Promise((resolve, reject) => {
        user
          .updateEmail(payload)
          .then(function() {
            ref
              .update({ email: payload })
              .then(() => {
                user.sendEmailVerification();
                context.commit("setUserEmail", payload);
                resolve();
              })
              .catch((err) => {
                reject(err);
              });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    //Subscribe Actions
    checkDiscountCode: (context, payload) => {
      const ref = fire_store
        .collection("meta")
        .where("discountCodes", "array-contains", payload)
        .limit(1);

      return new Promise((resolve, reject) => {
        ref
          .get()
          .then((snapshot) => {
            //if empty discount code not valid
            resolve(!snapshot.empty);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getOrderID: (context, payload) => {
      const hashFunction = fire_functions.httpsCallable("generateOrder");

      return new Promise((resolve, reject) => {
        hashFunction(payload)
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    getPremiumPassDoc: (context) => {
      const ref = fire_store.collection("meta").doc("premium_pass");

      return new Promise((resolve, reject) => {
        ref
          .get()
          .then((res) => {
            const data = res.data();
            context.commit("setPremiumPass", data);
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    //Test Actions
    // helper function, calls: getTestObj, getResultObj and getTestQuestions
    // payload = {testRef, testQuestions, isObj}
    getTestData: async (context, payload) => {
      var responsePayload = {
        questionsData: null,
        testObj: null,
        testResultObj: null,
      };

      var error = null;

      //if isObj only get questions (recieved ids and folderNames through testQuestions)
      if (payload.isObj) {
        const resultObjArr = await context
          .dispatch("getResultObj", payload.testRef.id)
          .catch((e) => (error = e));

        if (!error && resultObjArr.length > 0) {
          responsePayload.testResultObj = resultObjArr[0];
        }

        if (!error) {
          responsePayload.questionsData = await context
            .dispatch("getTestQuestions", payload.testQuestions)
            .catch((e) => (error = e));
        }
      } else {
        //if not isObj: it is a test id; get both testObj and use it to get questionsData

        const resultObjArr = await context
          .dispatch("getResultObj", payload.testRef)
          .catch((e) => (error = e));

        if (!error && resultObjArr.length > 0) {
          responsePayload.testResultObj = resultObjArr[0];
        }

        if (!error) {
          responsePayload.testObj = await context
            .dispatch("getTestObj", payload.testRef)
            .catch((e) => (error = e));
        }

        if (!error) {
          responsePayload.questionsData = await context
            .dispatch(
              "getTestQuestions",
              responsePayload.testObj.selectedQuestions
            )
            .catch((e) => (error = e));
        }
      }

      return new Promise((resolve, reject) => {
        if (error) {
          reject(error);
        } else {
          resolve(responsePayload);
        }
      });
    },

    getTestObj: (context, testID) => {
      const ref = fire_store.collection("testsv2").doc(testID);

      return new Promise((resolve, reject) => {
        ref
          .get()
          .then((res) => {
            const data = res.data();
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // helper function, calls: getFolderQuestions and getArrayQuestions
    getTestQuestions: async (context, testQuestionsObj) => {
      var responsePayload = {
        folderQuestions: {}, // {folderName:[]}
        questionsDataObj: {},
      };

      var error = null;

      var folderNamesArr = [];
      var questionsIDArr = [];

      // Populate above Arrays for firestore call
      Object.keys(testQuestionsObj).forEach((key) => {
        //if object it is an array : containing IDs of questions
        if (testQuestionsObj[key].constructor === Array) {
          //add it to folderNamesArr
          questionsIDArr = questionsIDArr.concat(testQuestionsObj[key]);
        } else {
          // else it is an obj with folder {folderName}
          folderNamesArr.push(testQuestionsObj[key].folderName);
        }
      });

      // if isObj only get questions (recieved ids and folderNames through testQuestions)

      //if not isObj: it is a test id; get both testObj and use it to get questionsData
      // can use destructuring
      responsePayload.folderQuestions = await context
        .dispatch("getFolderQuestions", folderNamesArr)
        .catch((e) => (error = e));

      if (!error) {
        responsePayload.questionsDataObj = await context
          .dispatch("getArrayQuestions", questionsIDArr)
          .catch((e) => (error = e));
      }

      //rn responsePayload.folderQuestions contains both allQuestions and folderQuestionIDs arr for each folder
      //move allQuestions from folderQuestions to questionsDataObj (concat-ish)
      responsePayload.questionsDataObj = {
        ...responsePayload.questionsDataObj,
        ...responsePayload.folderQuestions.allQuestions,
      };

      //responsePayload.folderQuestions = {folderName:[questionID_1, ....]}
      responsePayload.folderQuestions =
        responsePayload.folderQuestions.folderQuestionIDs;

      return new Promise((resolve, reject) => {
        if (error) {
          reject(error);
        } else {
          resolve(responsePayload);
        }
      });
    },

    // returns {allQuestions, folderQuestionIDs} where allQuestions is {questionID: questionData}
    // folderQuestionIDs is {folderName:Arr of IDs}
    getFolderQuestions: (context, folderNamesArr) => {
      var promises = [];

      folderNamesArr.forEach((folder) =>
        promises.push(
          fire_store
            .collection("questionsv2")
            .where("folder", "==", folder)
            .get()
        )
      );

      return new Promise((resolve, reject) => {
        var allQuestions = {};
        var folderQuestionIDs = {};

        return Promise.all(promises)
          .then((resArr) => {
            // resArr is array of objects for each folder respective to folderNamesArr

            resArr.forEach((folderRes, folderIndex) => {
              // folderQuestionIDs.folderName = [questionID_1, .....]
              folderQuestionIDs[folderNamesArr[folderIndex]] = [];

              if (!folderRes.empty) {
                folderRes.docs.forEach((doc) => {
                  //is a document
                  var docData = doc.data();

                  allQuestions[docData.id] = docData;

                  folderQuestionIDs[folderNamesArr[folderIndex]].push(
                    docData.id
                  );
                });
              }
            });

            resolve({ allQuestions, folderQuestionIDs });
          })
          .catch((error) => reject(error));
      });
    },

    getArrayQuestions: (context, questionsIDArr) => {
      var promises = [];

      questionsIDArr.forEach((id) =>
        promises.push(
          fire_store
            .collection("questionsv2")
            .doc(id)
            .get()
        )
      );

      return new Promise((resolve, reject) => {
        var allQuestions = {};

        return Promise.all(promises)
          .then((resArr) => {
            resArr.forEach((res) => {
              //is a document
              var docData = res.data();
              allQuestions[docData.id] = docData;
            });

            resolve(allQuestions);
          })
          .catch((error) => reject(error));
      });
    },

    submitTestResult: (context, payload) => {
      const ref = fire_store
        .collection("resultsv2")
        .doc(payload.testID)
        .collection("users")
        .doc(context.state.auth.uid);

      const scoresRef = fire_store.collection("resultsv2").doc(payload.testID);

      const scorePayload = {
        [payload.studentUID]: payload.score,
      };

      return new Promise((resolve, reject) => {
        ref
          .set(payload, { merge: true })
          .then(() => {
            context.commit("addResult", payload);

            // update score doc for the test
            scoresRef
              .set(scorePayload, { merge: true })
              .then(() => {
                resolve();
              })
              .catch((err) => {
                reject(err);
              });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    // helper function, calls: getResultObj, getTestScoresDoc, getTestObj and getTestQuestions
    // payload = {testID}
    getResultData: async (context, payload) => {
      var responsePayload = {
        questionsData: null,
        testObj: null,
        resultObj: null,
        testScoresObj: null,
        noAttempt: false,
        topFiveResults: [],
      };

      var error = null;

      //get resultObj if not found return with noAttempt flag true
      const resultObjArr = await context
        .dispatch("getResultObj", payload)
        .catch((e) => (error = e));

      if (!error && resultObjArr.length < 1) {
        responsePayload.noAttempt = true;
      } else {
        responsePayload.resultObj = resultObjArr[0];

        responsePayload.topFiveResults = await context
          .dispatch("getTopFiveResults", payload)
          .catch((e) => (error = e));

        if (!error) {
          //get testObj and use it to get questionsData
          responsePayload.testObj = await context
            .dispatch("getTestObj", payload)
            .catch((e) => (error = e));
        }

        if (!error) {
          responsePayload.testScoresObj = await context
            .dispatch("getTestScoresDoc", payload)
            .catch((e) => (error = e));
        }

        if (!error) {
          responsePayload.questionsData = await context
            .dispatch(
              "getTestQuestions",
              responsePayload.testObj.selectedQuestions
            )
            .catch((e) => (error = e));
        }
      }

      return new Promise((resolve, reject) => {
        if (error) {
          reject(error);
        } else {
          resolve(responsePayload);
        }
      });
    },

    getResultObj: (context, payload) => {
      const ref = fire_store
        .collection("resultsv2")
        .doc(payload)
        .collection("users")
        .where("studentUID", "==", context.state.auth.uid);

      return new Promise((resolve, reject) => {
        ref
          .get()
          .then((snapshot) => {
            const resArr = snapshot.docs.map((doc) => doc.data());
            resolve(resArr);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getTestScoresDoc: (context, testID) => {
      const ref = fire_store.collection("resultsv2").doc(testID);

      return new Promise((resolve, reject) => {
        ref
          .get()
          .then((res) => {
            const data = res.data();
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getTopFiveResults: (context, payload) => {
      const ref = fire_store
        .collection("resultsv2")
        .doc(payload)
        .collection("users")
        .orderBy("score", "desc")
        .limit(5);

      return new Promise((resolve, reject) => {
        ref
          .get()
          .then((snapshot) => {
            const resArr = snapshot.docs.map((doc) => doc.data());
            resolve(resArr);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    setToActiveTestData:(context, payload) => {
      context.commit("setToActiveTestData", payload);
    },

    resetActiveTestData(context){
      context.commit("resetActiveTestData");
    }
  },
});
